<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="name"
      class="mt-5"
      :rules="nameRules"
      label="Name"
      required
      outlined
    ></v-text-field>

    <v-text-field
      v-model="contact"
      :rules="contactRules"
      label="Contact Number"
      required
      outlined
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
      outlined
    ></v-text-field>

    <v-textarea
      label="Query"
      v-model="query"
      :rules="queryRules"
      required
      outlined
    ></v-textarea>

    <v-btn class="success">Send</v-btn>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    contact: '',
    contactRules: [
      (v) => !!v || 'Contact is required',
      (v) => (v && v.length > 9) || 'Please enter valid contact number',
    ],
    query: '',
    queryRules: [
      (v) => !!v || 'Cannot be empty',
      (v) => (v && v.length >= 10) || 'Must be greater than 10 characters',
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
