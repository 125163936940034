<template>
  <div class="wrapper">
    <h2 class="header-title text-center mt-5">Get In touch</h2>
    <div id="contact-body" class="page-view text-center">
      <div class="contact-data">
        <h3 class="sub-header">Please connect with us at</h3>
        <h3 class="sub-header no-case">info@atesconsulting.in</h3>
        <Form />
      </div>
      <div class="map-data">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              class="gmap_iframe"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=h-221,sector 63, noida &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Form from '@/components/Contact/Form.vue';
import Footer from '@/components/UI/Footer.vue';
export default {
  components: { Form, Footer },
};
</script>

<style scoped>
#contact-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 5rem;
  align-items: flex-start;
}
.map-data {
  height: 100%;
}
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 100%;
}
.gmap_iframe {
  height: 90% !important;
  min-height: 300px;
}

.no-case {
  text-transform: lowercase;
  font-weight: bold;
}

@media (max-width: 768px) {
  #contact-body {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
